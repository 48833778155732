.KeenSliderWrapper {
    [class~='keen-slider'] {
        display: flex;
        overflow: hidden;
        position: relative;
        user-select: none;
        -webkit-touch-callout: none;
        -khtml-user-select: none;
        -ms-touch-action: pan-y;
        touch-action: pan-y;
        -webkit-tap-highlight-color: transparent;
    }
    [class~='keen-slider__slide'] {
        position: relative;
        overflow: hidden;
        width: 100%;
        min-height: 100%;
    }

    //Use data-keen-slider-v styles when slider is vertical
    div[data-keen-slider-v] {
        flex-wrap: wrap;
    }

    div[data-keen-slider-v__slide] {
        width: 100%;
    }

    div[data-keen-slider-moves] * {
        pointer-events: none;
    }
}
